



































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import RunningNumberService from "@/services/RunningNumberService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import InfoViewMixin from "@/components/shared/InfoViewMixin";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { EditorControlView, AlertDialogView },
    data: () => ({
        runningNumber: {},
        saving: false,
    }),
})
export default class RunningNumberInfoView extends Mixins(InfoViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private editorControl = new EditorControlProperties();
    private runningNumberService = new RunningNumberService();

    public get resetByItems() {
        return [
            {
                text: this.$t("text.none"),
                value: "None",
            },
            {
                text: this.$t("text.daily"),
                value: "Daily",
            },
            {
                text: this.$t("text.monthly"),
                value: "Monthly",
            },
            {
                text: this.$t("text.yearly"),
                value: "Yearly",
            },
        ];
    }

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;
        this.mode = this.$route.params.mode;

        if (!this.checkMode() || this.isCreate()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

    public refreshSample() {
        const runningNumber = this.$data.runningNumber;
        const date = new Date();
        const length = runningNumber.length <= 0 ? 1 : runningNumber.length;
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDay();
        
        var sample = runningNumber.format;
        sample = sample.replace("%yyyy", year.toString().padStart(4, "0"));
        sample = sample.replace("%yy", year.toString().slice(-2).padStart(2, "0"));
        sample = sample.replace("%mm", month.toString().padStart(2, "0"));
        sample = sample.replace("%dd", day.toString().padStart(2, "0"));
        sample = sample.replace("%nn", "1".padStart(length, "0"));

        runningNumber.sample = sample;
    }

    public async load() {
        var id = this.$route.query.id;

        try {
            const r = await this.runningNumberService.get({ id });
            if (r.data.runningNumbers.length === 0) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            this.$data.runningNumber = r.data.runningNumbers[0];
            this.refreshSample();
        } catch (e) {
            var m = ExceptionUtil.getMessage(e);
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.tag = { command: "load-error" };
            this.alertDialog.visible = true;
        }
    }

    public async control(button: string) {
        if (button === "cancel") {
            this.cancel();
        } else if (button === "save") {
            await this.save();
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const r = await this.runningNumberService.put(this.$data.runningNumber);
            this.$data.runningNumber = r.data;

            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.visible = true;
        } catch (e) {
            var m = ExceptionUtil.getMessage(e);
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        } finally {
            this.$data.saving = false;
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "discard-changes") {
            if (button === this.$t("text.yes") && !this.$data.saving) {
                await this.load();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && (tag.command === "invalid-mode" || tag.command === "load-error")) {
            await this.$router.push("/management/settings/running-numbers");
        }
    }
}
