import AbstractService from "./AbstractService";
import Axios from "axios";

export default class RunningNumberService extends AbstractService {
    private controller = "running-numbers";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public put(runningNumber: any = null) {
        const d = { runningNumber };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
}